import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../assets/images/purchase.jpg';

const HeaderWrapper = styled.header`
  /* height: 500px; */
  /* padding: 0 15px; */
  display: flex;
  align-items: center;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center 50%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  /* @media (min-width: ${({ theme }) => theme.smLaptop}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0 5%;
  } */
`;

const Content = styled.div`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  width: 100%;
  max-width: 1200px;
  position: relative;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const Headline = styled.h1`
  font-size: ${({ theme }) => theme.size24};
  font-weight: ${({ theme }) => theme.semiBold};
  color: white;
  text-align: center;
  /* margin-bottom: 30px; */
`;

const SpecificHeader = () => {
  return (
    <HeaderWrapper>
      <Content>
        <Headline>
          Zostań partnerem biznesowym i pomnażaj oszczędności inwestując w
          nieruchomości
        </Headline>
      </Content>
    </HeaderWrapper>
  );
};

export default SpecificHeader;
