import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import SpecificHeader from '../../components/atoms/SpecificHeader';
import styled from 'styled-components';
import Adwokaci from '../../assets/images/Adwokaci.jpg';
import Doradcy from '../../assets/images/Doradcy.jpg';
import Architekci from '../../assets/images/Architekci.jpg';
import Ekipa from '../../assets/images/Ekipa.jpg';
import Arrow from '../../assets/images/Arrow_long.png';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import TheyWorkWithUs from '../../components/organisms/TheyWorkWithUs';
import HowCooperateWorks from '../../components/atoms/HowCooperateWorks';
import ContactButton from '../../components/atoms/ContactButton';

const TopText = styled.p`
  font-size: ${({ theme }) => theme.size18};
  font-weight: ${({ theme }) => theme.medium};
  /* color: white; */
`;

const IconsWrapper = styled.div`
  margin-top: 15px;
  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: flex;
    justify-content: space-between;
  }
`;

const IconBox = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    max-width: 280px;
    margin-bottom: 0;
  }
  @media (min-width: ${({ theme }) => theme.desktop}) {
    max-width: 320px;
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  width: 38px !important;
  height: 38px !important;
  min-width: 38px;
  flex-basis: 38px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.yellow};
  flex-basis: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const IconContent = styled.div``;

const IconText = styled.p`
  font-size: ${({ theme }) => theme.size14};
  font-weight: ${({ theme }) => theme.light};
  /* color: white; */
`;

const IconHeadline = styled.p`
  font-size: ${({ theme }) => theme.size16};
  font-weight: ${({ theme }) => theme.medium};
  /* color: white; */
  margin-bottom: 4px;
`;

const FirstSection = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /* align-items: center; */
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const FirstSectionLeft = styled.div`
  img {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    width: 20%;

    img {
      display: block;
    }
  }
`;

const FirstSectionRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    display: grid;
    justify-content: center;
    grid-gap: 10px;
    grid-template-columns: repeat(2, minmax(1%, 250px));
    grid-template-rows: repeat(2, minmax(1%, 250px));
  }
  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    width: 75%;
    justify-content: flex-start;
    grid-template-columns: repeat(2, minmax(1%, 250px));
    grid-template-rows: repeat(2, minmax(1%, 250px));
  }
  @media (min-width: ${({ theme }) => theme.laptop}) {
    width: 75%;
    grid-template-columns: repeat(4, minmax(25%, 250px));
    grid-template-rows: 1fr;
  }
`;

const Headline = styled.h1`
  font-size: ${({ theme }) => theme.size24};
  font-weight: ${({ theme }) => theme.semiBold};
  line-height: 130%;
  letter-spacing: 0%;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  position: relative;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    height: 100%;
    aspect-ratio: 1/1;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 250px;
  }
`;

const Title = styled.div`
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100%;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: ${({ theme }) => theme.size16};
  font-weight: ${({ theme }) => theme.semiBold};

  @media (min-width: ${({ theme }) => theme.tablet}) {
    bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    bottom: 6px;
  }
`;

const SecondSection = styled.section`
  margin: 50px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const SecondSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const NumeralBox = styled.div`
  max-width: 365px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.laptop}) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    max-width: 200px;
  }
`;

const Number = styled.div`
  width: 45px;
  height: 45px !important;
  background-color: ${({ theme }) => theme.yellow};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
`;

const NumeralBoxText = styled.p`
  font-size: ${({ theme }) => theme.size14};
  font-weight: ${({ theme }) => theme.light};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.desktop}) {
    font-size: ${({ theme }) => theme.size15};
  }
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.size14};
  font-weight: ${({ theme }) => theme.light};

  @media (min-width: ${({ theme }) => theme.desktop}) {
    font-size: ${({ theme }) => theme.size15};
  }
`;

const ThirdSection = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const FirstSectionLeftContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    width: 50%;
  }
`;

const FirstSectionRightContainer = styled.div`
  display: flex;
  justify-content: center;

  .gatsby-image-wrapper {
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.25);
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: block;
    width: 45%;
  }
`;

const BusinessPartnerPage = () => {
  const cms = useStaticQuery(query);
  const numeralBoxes = [
    'Zakup mieszkania dla siebie - na własne potrzeby mieszkaniowe',
    'Brak możliwości zakupu mieszkania - nieruchomości',
    'Wyjazdy służbowe - wynajem mieszkania na czas służbowego pobytu',
    'Wynajem mieszkania podczas urlopu turystycznego',
  ];

  console.log(cms.datoCmsBusinessPartnerPage.numeralBox);
  return (
    <MainTemplate
      isMainPage={false}
      title='Zostań partnerem biznesowym i pomnażaj oszczędności inwestując w nieruchomości'
      metaDescription='Zostań partnerem biznesowym i pomnażaj oszczędności inwestując w nieruchomości'
    >
      <SpecificHeader />

      <SecondSection>
        <Headline style={{ textAlign: 'center', maxWidth: '650px' }}>
          {/* Dlaczego właśnie my? */}
          {cms.datoCmsBusinessPartnerPage.firstSectionTitle}
        </Headline>
        <IconsWrapper>
          <IconBox>
            <Icon>
              <svg
                width='11'
                height='19'
                viewBox='0 0 11 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.381165 16.8246L1.99026 18.4337L10.9903 9.43365L1.99026 0.433655L0.381165 2.04275L7.77207 9.43365L0.381165 16.8246Z'
                  fill='white'
                />
              </svg>
            </Icon>
            <IconContent>
              <IconHeadline>
                {/* Umiejętności  */}
                {cms.datoCmsBusinessPartnerPage.firstSectionBlockTitleFirst}
              </IconHeadline>
              <IconText>
                {/* Wejdź do jednego z mieszkań, sam oceń jakość naszej pracy */}
                {cms.datoCmsBusinessPartnerPage.firstSectionBlockTextFirst}
              </IconText>
            </IconContent>
          </IconBox>
          <IconBox>
            <Icon>
              <svg
                width='11'
                height='19'
                viewBox='0 0 11 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.381165 16.8246L1.99026 18.4337L10.9903 9.43365L1.99026 0.433655L0.381165 2.04275L7.77207 9.43365L0.381165 16.8246Z'
                  fill='white'
                />
              </svg>
            </Icon>
            <IconContent>
              <IconHeadline>
                {/* Zaufanie */}
                {cms.datoCmsBusinessPartnerPage.firstSectionBlockTitleSecond}
              </IconHeadline>
              <IconText>
                {/* Firma z doświadczeniem, pomogliśmy wielu osobom znaleźć ich
                wymarzone mieszkanie */}
                {cms.datoCmsBusinessPartnerPage.firstSectionBlockTextSecond}
              </IconText>
            </IconContent>
          </IconBox>
          <IconBox>
            <Icon>
              <svg
                width='11'
                height='19'
                viewBox='0 0 11 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.381165 16.8246L1.99026 18.4337L10.9903 9.43365L1.99026 0.433655L0.381165 2.04275L7.77207 9.43365L0.381165 16.8246Z'
                  fill='white'
                />
              </svg>
            </Icon>
            <IconContent>
              <IconHeadline>
                {/* Pasja */}
                {cms.datoCmsBusinessPartnerPage.firstSectionBlockTitleThird}
              </IconHeadline>

              <IconText>
                {/* Pasja rodzi profesjonalizm, profesjonalizm daje jakość */}
                {cms.datoCmsBusinessPartnerPage.firstSectionBlockTextThird}
              </IconText>
            </IconContent>
          </IconBox>
        </IconsWrapper>
      </SecondSection>
      <FirstSection>
        <FirstSectionLeft>
          <Headline>Wsparcie profesjonalistów</Headline>
          <img src={Arrow} alt='' />
        </FirstSectionLeft>
        <FirstSectionRight>
          <ImageWrapper>
            <img src={Adwokaci} alt='Adwokaci' />
            <Title>Adwokaci</Title>
          </ImageWrapper>
          <ImageWrapper>
            <img src={Doradcy} alt='Doradcy podatkowi' />
            <Title>Doradcy podatkowi</Title>
          </ImageWrapper>
          <ImageWrapper>
            <img src={Architekci} alt='Architekci' />
            <Title>Architekci</Title>
          </ImageWrapper>
          <ImageWrapper>
            <img src={Ekipa} alt='Profesjonalna ekipa remontowa' />
            <Title>Ekipa remontowa</Title>
          </ImageWrapper>
        </FirstSectionRight>
      </FirstSection>

      {/* Section "Dlaczego nieruchomości?" */}

      <SecondSection>
        <Headline style={{ textAlign: 'center', maxWidth: '650px' }}>
          {/* Dlaczego nieruchomości? */}
          {cms.datoCmsBusinessPartnerPage.secondSectionTitle}
        </Headline>
        <Text
          style={{
            textAlign: 'center',
            maxWidth: '473px',
            marginBottom: '50px',
          }}
          dangerouslySetInnerHTML={{
            __html: cms.datoCmsBusinessPartnerPage.secondSectionText,
          }}
        />
        {/* Bezpieczeństwo inwestowania w nieruchomości wynika z konieczności
          zaspokojenia potrzeb mieszkaniowych. */}

        <SecondSectionContent>
          {cms.datoCmsBusinessPartnerPage.numeralBox.map((edge, id) => (
            <NumeralBox>
              <Number>
                <p>{id + 1}</p>
              </Number>
              <NumeralBoxText
                dangerouslySetInnerHTML={{
                  __html: edge.text,
                }}
              />
            </NumeralBox>
          ))}
        </SecondSectionContent>
      </SecondSection>

      {/* Section: "Nawiązanie współpracy" */}

      <ThirdSection>
        <FirstSectionLeftContainer>
          <Headline>
            {/* Nawiązanie współpracy */}
            {cms.datoCmsBusinessPartnerPage.thirdSectionTitle}
          </Headline>
          {/* cms.datoCmsBusinessPartnerPage.thirdSectionTitle */}
          {/* <Text>
            <strong>Indywidualne podejście</strong> - współpraca to zaspokojenie
            oczekiwań partnera przy jednoczesnym zabezpieczeniu kapitału -
            oferujemy wiele możliwości inwestycji oraz wiele możliwości
            zabezpieczenia kapitału, to jaką wybierzesz zależy wyłącznie od
            Ciebie.
          </Text> */}
          <Text
            style={{ marginBottom: '20px' }}
            dangerouslySetInnerHTML={{
              __html: cms.datoCmsBusinessPartnerPage.thirdSectionText,
            }}
          />
          {/* <strong>Wiele możliwości</strong> - Inwestycje pasywne, inwestycje w
            wynajem/produkty inwestycyjne, obrót nieruchomościami - wybierz co
            najbardziej Ci odpowiada i skontaktuj się z nami. */}
          <ContactButton />
        </FirstSectionLeftContainer>

        <FirstSectionRightContainer>
          <GatsbyImage image={cms.hand.childImageSharp.gatsbyImageData} />
        </FirstSectionRightContainer>
      </ThirdSection>

      <HowCooperateWorks />

      <TheyWorkWithUs isSmall />
    </MainTemplate>
  );
};

const query = graphql`
  query BusinessPartnerQuery {
    hand: file(name: { eq: "businesspartner" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: NONE, quality: 100)
      }
    }
    datoCmsBusinessPartnerPage {
      firstSectionBlockTextFirst
      firstSectionBlockTextSecond
      firstSectionBlockTextThird
      firstSectionBlockTitleSecond
      firstSectionBlockTitleThird
      firstSectionBlockTitleFirst
      firstSectionTitle
      numeralBox {
        text
      }
      secondSectionText
      secondSectionTitle
      thirdSectionText
      thirdSectionTitle
    }
  }
`;

export default BusinessPartnerPage;
